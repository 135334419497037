@charset "utf-8";

/*---google font
<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" rel="stylesheet">
.tx_hel{font-family: 'Open Sans', sans-serif; font-weight: 400;}
.tx_rob{font-family: 'Roboto', sans-serif;  font-weight: 400;}
.thin{font-weight: 300; }
.bold{font-weight: 500; }
--------------------------- */

/******************************************************

 body
 
******************************************************/

html {
    font-size: 10px;
}

body {
    font-family: 'Hiragino Sans', 'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Robot, Meiryo, 'メイリオ', "ＭＳ Ｐゴシック", sans-serif;
    line-height: 1;
    color: #525252;
}

a:link, a:visited {
    color: inherit;
    text-decoration: none;
}

a:hover, a:active {
    text-decoration: none;
}

a.rollover {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    &:hover {
        opacity: 0.8;
        filter: alpha(opacity=80);
        -ms-filter: "alpha(opacity=80)";
        -moz-opacity: 0.8;
        -khtml-opacity: 0.8;
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.pc, .pc_in {
    display: none;
}

.sp {
    display: block;
}

.sp_in {
    display: inline-block;
}

@media screen and (min-width: 768px) {
    .pc {
        display: block;
    }
    .pc_in {
        display: inline-block;
    }
    .sp, .sp_in {
        display: none;
    }

}

/******************************************************

 header
 
******************************************************/

/* globalNav--------------------------------------------- */


